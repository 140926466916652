import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.78rem 0 0.95rem 0;
  background: rgb(249, 249, 249);
  > h2 {
    font-family: 'ITCAvantGardeStd-XLt';
    color: #969696;
    font-size: 0.16rem;
    text-transform: uppercase;
  }
  > h3 {
    color: #636363;
    font-size: 0.3rem;
    font-weight: 800;
  }
  .line {
    width: 0.9rem;
    height: 2px;
    background-color: #ff7c34;
    margin: 0.2rem auto 0;
  }
  .desc {
    font-size: 0.22rem;
    margin-top: 0.36rem;
    > p {
      text-align: center;
      color: #787878;
      margin-bottom: 0.12rem;
    }
  }
  .btn {
    margin-top: 0.44rem;
    padding: 0.17rem 0.7rem;
    background: rgb(255, 124, 52);
    box-shadow: 0px 5px 6px 0px rgba(56, 56, 56, 0.14);
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.26rem;
  }
`;
export default function Requirement() {
  return (
    <Wrapper>
      <h3>平台开通要求</h3>
      <h2>Opening requirement</h2>
      <p className="line" />
      <div className="desc">
        <p>您只须向腾讯公司申请一个公众号，</p>
        <p>把它交给壹点壹滴，壹点壹滴5分钟</p>
        <p>就可赋能这个公众号，让它成为幼儿园服务平台。</p>
      </div>
      <a
        href="https://mp.weixin.qq.com/cgi-bin/registermidpage?action=index&lang=zh_CN"
        className="btn"
        rel="noopener noreferrer"
        target="_blank"
      >
        立即申请幼儿园微信公众号
      </a>
    </Wrapper>
  );
}
