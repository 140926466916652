import React from 'react';
import styled from 'styled-components';
import TiziImg from 'imgs/1d1d/pc/join.tizi.png';
import links from 'RootCommon/links';
const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.78rem 0 0.95rem 0;
  > h2 {
    font-family: 'ITCAvantGardeStd-XLt';
    color: #969696;
    font-size: 0.16rem;
    text-transform: uppercase;
  }
  > h3 {
    color: #636363;
    font-size: 0.3rem;
    font-weight: 800;
  }
  .line {
    width: 0.9rem;
    height: 2px;
    background-color: #ff7c34;
    margin: 0.2rem auto 0;
  }
  .desc {
    font-size: 0.22rem;
    margin-top: 0.36rem;
    > p {
      text-align: center;
      color: #787878;
      margin-bottom: 0.12rem;
    }
  }
  .info {
    margin-top: 0.4rem;
    > img {
      width: 4rem;
      margin: 0 auto;
      display: block;
    }
    .txt {
      margin-top: 0.9rem;
      padding: 0 0.5rem;

      > h3 {
        font-weight: bold;
        font-size: 0.22rem;
        color: #2c2c2c;
      }
      > p {
        font-size: 0.22rem;
        color: #787878;
        margin-top: 0.18rem;
        margin-bottom: 0.6rem;
      }
    }
  }
  .btn {
    margin-top: 0.44rem;
    padding: 0.17rem 0.7rem;
    background: rgb(255, 124, 52);
    box-shadow: 0px 5px 6px 0px rgba(56, 56, 56, 0.14);
    border-radius: 0.25rem;
    color: #fff;
    font-size: 0.26rem;
  }
`;
export default function Companion() {
  return (
    <Wrapper>
      <h3>壹点壹滴幼儿园成长伙伴</h3>
      <h2>Opening requirement</h2>
      <p className="line" />
      <div className="desc">
        <p>在助力幼儿园全面升级的道路上，壹点壹滴一路相随。</p>
        <p>陪伴园长、教师、家长、孩子共同成长，为您解决幼儿园经营烦恼。</p>
      </div>
      <div className="info">
        <img src={TiziImg} alt="成长伙伴配图" />
        <div className="txt">
          <h3>★ 全面赋能幼儿园，为园所成长保驾护航</h3>
          <p>我们会在您付款后24小时内和您取得联系，确认园所信息，为您提供优质的客服服务。</p>
          <h3>★ 为园所安排专属督导，答疑解惑提升效能</h3>
          <p>我们会根据幼儿园所在地区，为您安排区域专属督导，保证幼儿园公众号的正常运营。</p>
          <h3>★ 定期沟通和培训，见证您的每一次成长</h3>
          <p>
            平台开通后，我们会定期安排总部督导对公众号的使用及运营进行区域培训，让您在学习中成长。
          </p>
        </div>
      </div>
      <a href={links.onlineChat} rel="noopener noreferrer" target="_blank" className="btn">
        在线咨询
      </a>
    </Wrapper>
  );
}
